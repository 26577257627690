import { Identity } from './app/service/login.service';

export enum Roles {
    Admin = 'admin',
    Helpdesk = 'helpdesk',
    Reader = 'reader',
    Designer = 'designer',
    SubscriptionManager = 'subscription-manager',
    CustomerInformationManager = 'customer-information-manager',
    BlockedIndividualsManager = 'blocked-individuals-manager',
    SelfServiceUser = 'self-service-user',
    SelfServiceUserFetchManufacturingData = "self-service-user-–-fetch-manufacturing-data",
    InternalSelfServiceUser = 'internal-self-service-access',
    DocumentAdmin = 'document-admin',
    DetailedAnalyticsReader = 'detailed-analytics-reader',
}

export const evaluatePolicy = (identity: Identity, acceptedRoles: string[]) => {
    // Se om någon av användarens roller är en accepterad roll
    for (const role of identity.roles) {
        if (acceptedRoles.indexOf(role) >= 0) {
            return true;
        }
    }
    return false;
};

export class Policies {
    // TODO: Framtida förbättringar: synka policies vid inloggning (be server skicka upp policyer)
    static readonly CanReadStatistics = [
        Roles.Admin,
        Roles.Helpdesk,
        Roles.Reader,
        Roles.Designer,
        Roles.SubscriptionManager,
        Roles.CustomerInformationManager,
        Roles.BlockedIndividualsManager,
        Roles.InternalSelfServiceUser,
        Roles.DetailedAnalyticsReader,
        Roles.DocumentAdmin
    ];
    static readonly CanReadAllErrors = [
        Roles.Admin,
        Roles.Helpdesk,
        Roles.Reader,
        Roles.Designer,
        Roles.SubscriptionManager,
        Roles.CustomerInformationManager,
        Roles.BlockedIndividualsManager,
        Roles.InternalSelfServiceUser,
        Roles.DetailedAnalyticsReader,
        Roles.DocumentAdmin
    ];
    static readonly CanReadErrorsInOwnUnits = [
        Roles.Admin,
        Roles.Helpdesk,
        Roles.Reader,
        Roles.Designer,
        Roles.SubscriptionManager,
        Roles.CustomerInformationManager,
        Roles.BlockedIndividualsManager,
        Roles.SelfServiceUser,
        Roles.SelfServiceUserFetchManufacturingData,
        Roles.InternalSelfServiceUser,
        Roles.DetailedAnalyticsReader,
        Roles.DocumentAdmin
    ];
    static readonly CanReadLegacyErrors = [
        Roles.Admin,
        Roles.Helpdesk
    ];
    static readonly CanUpdateErrors = [
        Roles.Admin,
        Roles.Helpdesk
    ];
    static readonly CanDoBatchCorrection = [
        Roles.Admin,
        Roles.Helpdesk
    ];
    static readonly AllowAll = [
        Roles.Admin,
        Roles.Helpdesk,
        Roles.Reader,
        Roles.Designer,
        Roles.SubscriptionManager,
        Roles.CustomerInformationManager,
        Roles.BlockedIndividualsManager,
        Roles.SelfServiceUser,
        Roles.SelfServiceUserFetchManufacturingData,
        Roles.InternalSelfServiceUser,
        Roles.DetailedAnalyticsReader,
        Roles.DocumentAdmin
    ];
    static readonly CanDoAlgorithmAssistedCorrection = [
        Roles.Admin,
        Roles.Helpdesk
    ];
}
